<template>
  <v-container
    fluid
  >
    <v-alert
      v-if="message.length"
      color="red"
      type="error"
    >
      {{ message }}
    </v-alert>
    <v-card elevation="0" rounded="xl">
      <v-card-title>
        <v-img
          style="max-width: 30px;"
          class="mr-3"
          :src="require('../assets/webport.png')"
        />Webport Integration <v-spacer /> <v-btn
          color="green"
          dark
          @click="getData"
        >
          Refresh Data
        </v-btn>
      </v-card-title>
      <v-progress-circular
        v-if="loading"
        indeterminate
        :size="40"
        color="green"
        class="loader"
      />
      <v-card-text :class="loading ? 'disabled' : ''">
        <div>
          <v-divider />
          <div class="headline mt-5">
            1. Webport token
          </div>
          <div class="mt-5 mb-5">
            <v-btn
              v-if="!token"
              color="blue"
              dark
              @click="generateWebportToken"
            >
              Generate token
            </v-btn>
            <v-btn
              v-else-if="token"
              color="orange"
              :disabled="!refreshTokenEnabled"
              :dark="!refreshTokenEnabled ? false : true"
              @click="refreshToken"
            >
              Refresh token
            </v-btn>
            <span class="pl-5" :class="token ? 'green--text' : 'red--text'"> <span class="blue--text font-weight-bold">Token:</span> {{ token ? token : 'No token generated yet!' }}</span>
            <span class="pl-5" :class="token ? 'green--text' : 'red--text'"> <span class="blue--text font-weight-bold">Valid Until:</span> {{ validUntil ? validUntil : 'No token generated yet!' }}</span>
            <span v-if="token" class="pl-5"> <span class="blue--text font-weight-bold">Auto-refresh:</span> <v-icon color="green">mdi-check</v-icon></span>
          </div>
          <v-divider />
          <div class="headline mt-5 mb-5">
            2. Webport read tag
          </div>
          <div class="mt-5">
            <v-btn
              :disabled="!token || !tagToRead"
              color="blue"
              :dark="!token || !tagToRead ? false : true"
              @click="readTag"
            >
              Read tag
            </v-btn>
            <span v-if="!token" class="pl-5 red--text"> No token generated yet!</span>
            <div v-else style="display: inline-block; margin-left: 10px; width: 300px;">
              <v-text-field
                v-model="tagToRead"
                dense
                outlined
                label="Tag to read"
              />
            </div>
            <div class="mt-2">
              <v-textarea
                outlined
                :value="response"
                label="Webport Response"
                disabled
              />
            </div>
          </div>
          <v-divider />
          <div class="headline mt-5">
            3. Health
          </div>
          <div class="mt-5">
            <span>Status: <v-icon v-if="token" color="green">mdi-check</v-icon> <v-icon v-else color="red">mdi-close</v-icon> </span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import dayjs from 'dayjs'

export default {
  name: 'Webport',
  components: {
  },
  data () {
    return {
      dayjs,
      loading: false,
      refreshTokenEnabled: false,
      token: '',
      validUntil: '2022-05-04 06:25:11+00',
      response: '',
      tagToRead: '',
      message: ''
    }
  },
  watch: {
    validUntil: function (val) {
      if ((dayjs(val).diff(dayjs(), 'day') <= 2)) {
        this.refreshTokenEnabled = true
      } else {
        this.refreshTokenEnabled = false
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      this.$store.dispatch('getWebportDetails')
        .then(resp => {
          this.token = resp.data.token
          this.validUntil = resp.data.validUntil
          this.loading = false
        })
        .catch(err => {
          this.message = err.response.data
          this.loading = false
        })
    },
    generateWebportToken () {
      this.loading = true
      this.$store.dispatch('generateWebportToken')
        .then(resp => {
          setTimeout(() => {
            this.getData()
          }, 2000)
        })
        .catch(err => {
          this.message = err.response.data
          this.loading = false
        })
    },
    refreshToken () {
      this.loading = true
      this.$store.dispatch('refreshWebportToken')
        .then(resp => {
          setTimeout(() => {
            this.getData()
          }, 2000)
        })
        .catch(err => {
          this.message = err.response.data
          this.loading = false
        })
    },
    readTag () {
      this.$store.dispatch('readWebportTag', { tag: this.tagToRead })
        .then(resp => {
          this.response = JSON.stringify(resp.data)
        })
        .catch(err => {
          this.message = err.response.data
        })
    }
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
